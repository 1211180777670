export const DIALOG = {
  SHOW: "showGenericDialog",
  HIDE: "hideGenericDialog",
  SIMPLE_TYPE: "simpleType",
  ONE_BUTTON_TYPE: "oneButtonType",
  TWO_BUTTON_TYPE: "twoButtonType",
};
export const DIALOG_ICON = {
  QUESTION: "question",
};
export const UPDATE_PROFILE = {
  PERSONAL: "frontOfficePersonalProfileUpdate",
  PERSONAL_LOADING: "frontOfficePersonalProfileUpdateLoading",
  ENTERPRISE: "frontOfficeEnterpriseProfileUpdate",
  PASSWORD: "changePassword",
};

export const APPLICATIONS_ACTIONS = {
  CREATE: "createApplication",
  GET_COMPLEMENTARY_DATA: "getSubprogramComplementaryData",
  GET_SUBPROGRAM_INFORMATION: "getSubprogramInformation",
  GET_APPLICATIONS_IN_PROGRESS: "getApplicationsInProgress",
  GET_APPLICATION_DETAILS_GENERAL_DATA: "getApplicationDetailsGeneralData",
  GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING:
    "getApplicationDetailsGeneralDataLoading",
  UPDATE_APPLICATION_DETAILS_GENERAL_DATA: "updateApplicationDetailGeneralData",
  GET_SUBMIT_LOADING: "getSubmitLoading",
  GET_USER_APPLICATIONS_LOADING: "getUserApplicationsLoading",
  RESET_FORM: "resetCreateApplicationForm",
  GET_APPLICATION_DOCUMENT_DATA_DOWNLOAD: "getApplicationDocumentData",
};

export const OPPORTUNITIES_ACTIONS = {
  CREATE: "createOpportunity",
  GET_SUBMIT_LOADING: "getSubmitLoading",
  CREATE_OPPORTUNITY_CANDIDATES: "createOpportunityCandidate",
  GET_SUBMIT_OPPORTUNITY_CANDIDATE_LOADING: "getSubmitOpportunityCandidateLoading",
  UPDATE: "updateOpportunity",
  GET_UPDATE_LOADING: "getUpdateLoading",
  GET_FILTERED_OPPORTUNITIES_ACTIONS: "getFilteredOpportunities",
  GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING: "getFilteredOpportunitiesLoading",
  GET_FILTERED_AVAILABLE_OPPORTUNITIES_ACTIONS:
    "getFilteredAvailableOpportunities",
  GET_FILTERED__AVAILABLE_OPPORTUNITIES_ACTIONS_LOADING:
    "getFilteredAvailableOpportunitiesLoading",
  GET_RECOMMENDED_OPPORTUNITIES_ACTIONS: "getRecommendedOpportunities",
  GET_RECOMMENDED_OPPORTUNITIES_ACTIONS_LOADING:
    "getRecommendedOpportunitiesLoading",
  GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING: "getOpportunityDetails",
  GET_OPPORTUNITY_DETAILS_ACTIONS: "getOpportunityDetailsAction",
  CLEAR_OPPORTUNITY_DETAILS_ACTIONS:"clearOpportunityDetailsAction",
  UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING: "updateOpportunityStateLoading",
  GET_CANDIDATE_INFORMATION: "getCandidateInformation",
  GET_CANDIDATE_INFORMATION_LOADING: "getCandidateInformationLoading",
  GET_OPPORTUNITY_USERS_ACTIONS: "getOpportunityUsers",
  GET_OPPORTUNITY_USERS_ACTIONS_LOADING: "getOpportunityUsersLoading",
  APPROVE_CANDIDATE_LOADING: "approveOpportunityCandidateLoading",
  REJECT_CANDIDATE_LOADING: "rejectOpportunityCandidateLoading",
  GET_OPPORTUNITY_DOCUMENT: "getOpportunityDocument",
  GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES: "getFilteredPossibleOpportunityCandidates",
  GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING: "getFilteredPossibleOpportunityCandidatesLoading",
  ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING: "addOpportunityCandidatesListLoading",
  GET_MY_OPPORTUNITY_APPLICATIONS: "getMyOpportunityApplications",
  GET_MY_OPPORTUNITY_APPLICATIONS_LOADING: "getMyOpportunityApplicationsLoading",
  GET_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS_LOADING: "getOpportunityApplicationDetails",
  GET_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS: "getOpportunityApplicationDetailsAction",
  CLEAR_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS:"clearOpportunityApplicationDetailsAction",
  GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS: "getUserColectiveOpportunityApplications",
  GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_LOADING: "getUserColectiveOpportunityApplicationsLoading",
  GET_IS_OPPORTUNITY_APPLICATION: "getIsOpportunityApplication",
  GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP: "getMyOpportunityApplicationsFollowUp",
  GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING: "getMyOpportunityApplicationsFollowUpLoading",
  GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_FOLLOWUP: "getUserColectiveOpportunityApplicationsFollowUp",
  GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING: "getUserColectiveOpportunityApplicationsFollowUpLoading",
  GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS: "getOpportunityApplicationsFollowUpDetails",
  GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING: "getOpportunityApplicationsFollowUpDetailsLoading",
  UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING: "updateOpportunityFollowupPeriod",
  GET_FEEDBACK_REPORT_DETAILS: "getFeedbackReportDetails",
  GET_FEEDBACK_REPORT_DETAILS_LOADING: "getFeedbackReportDetailsLoading",
  UPDATE_FEEDBACK_REPORT_LOADING: "updateFeedbackReportLoading",
};

export const DATASET_ACTIONS = {
  GET_DATASET_BY_NAME: "getDataSetByName",
  GET_DATASET_BY_PROPERTY_ID: "getChildrenByPropertyId",
  GET_GEOGRAPHIC_DATA: "getGeographicData",
  GET_GEOGRAPHIC_DATA_BY_PROVINCE: "getGeographicDataByProvince",
  GET_NATIONALITIES: "getNationalities",
  GET_PROGRAMS: "getPrograms",
  GET_SUBPROGRAM_STATES: "getSubprogramStates",
  GET_DEPARTMENTS: "getDepartments",
  GET_CATEGORIES: "getCategories",
  GET_ALL_DATASET_TYPES: "getAllDatasetTypes",
  GET_WAREHOUSE_BY_PROVINCE: "getWarehousesByProvince",
  GET_LOSS_BREACK_MOTIVES: "getLossBreakMotives",
  GET_DOCUMENTS: "getDocuments",
  GET_CONDITIONS: "getConditions",
  GET_SUBPROGRAMS: "getSubprograms",
  GET_DATASET_BY_INDEX: "getDataSetByIndex",
  GET_USER_APPLICATIONS_AWAITING_TRAINING:
    "getUserApplicationsAwaitingTraining",
  GET_ALL_PROGRAMS: "getAllPrograms",
  GET_SUBPROGRAM_BY_PROGRAM_ID: "getSubprogramByProgramId",
  GET_SHAPE_PROGRAMS: "getShapePrograms",
  GET_SHAPE_SUBSITES: "getShapeSubsites",
  CLEAR_DATASET_BY_NAME: "clearDataSetByName",
};

export const NOTIFICATION = {
  GET_NOTIFICATIONS: "getNotifications",
  GET_NOTIFICATIONS_LOADING: "getNotificationsLoading",
  GET_UNREAD_NOTIFICATIONS: "getUnreadNotifications",
  GET_UNREAD_NOTIFICATIONS_LOADING: "getUnreadNotificationsLoading",
  UPDATE_NOTIFICATION: "updateNotifications",
  CREATE: "createNotification",
  GET_TOTAL_UNREAD_NOTIFICATIONS: "getNumberOfUnreadNotifications",
};

export const AUTH = {
  SESSION_EXPIRED: "session_expired",
  RESET_SESSION_EXPIRED: "reset_session_expired",
  RECOVER_PASSWORD: "recover_password",
  RECOVER_PASSWORD_LOADING: "recover_password_loading",
  RESET_RECOVER_STATE: "resetRecoverState",
  RESET_PASSWORD: "reset_password",
  RESET_PASSWORD_LOADING: "reset_password_loading",
  RESET_RESET_PASSWORD_STATE: "reset_reset_password_state",
  RESET_LOGIN_FORM: "reset_login_form",
  GET_PERSONAL_INFORMATION: "get_personal_information",
  GET_PERSONAL_INFORMATION_LOADING: "get_personal_information_loading",
  GET_USER_DOCUMENT_DATA_DOWNLOAD: "getUserDocument",
  GET_APPLICATION_VERSION : "getApplicationVersion",
  GET_APPLICATION_VERSION_LOADING : "application_version_loading"
};

export const INFORMATION_DISSEMINATION = {
  CREATE: "createInformation",
  CREATE_INFORMATION_DISSEMINATION_LOADING: "createInformation_loading",
  GET_FILTERED_INFORMATION_DISSEMINATION: "getFilteredInformation",
  GET_FILTERED_INFORMATION_DISSEMINATION_LOADING:
    "getFilteredInformationLoading",
  GET_INFORMATION_DISSEMINATION_DETAILS: "getInformationDetails",
  GET_INFORMATION_DISSEMINATION_DETAILS_LOADING: "getInformationDetailsLoading",
  REFRESH_RESULTS: "informationRefreshResults",
  CLEAR_DETAIL: "clearInformation",
  UPDATE: "updateInformation",
  UPDATE_INFORMATION_DISSEMINATION_LOADING: "updateInformation_loading",
};

export const MENTORING = {
  GET_MY_MENTORS: "getMyMentors",
  GET_MY_MENTORS_LOADING: "getMyMentorsLoading",
  GET_MY_MENTEES: "getMyMentees",
  GET_MY_MENTEES_LOADING: "getMyMenteesLoading",
  BECOME_MENTOR: "becomeMentor",
  BECOME_MENTOR_LOADING: "becomeMentorLoading",
  REQUEST_MENTOR: "requestMentor",
  REQUEST_MENTOR_LOADING: "requestMentorLoading",
  GET_MENTORING_DETAILS: "getMentoringDetails",
  GET_MENTORING_DETAILS_LOADING: "getMentoringDetailsLoading",
  REFRESH_RESULTS: "mentoringRefreshResults",
  CLEAR_DETAIL: "clearMentoringDetails",
  SEND_NEW_MESSAGE_LOADING: "sendNewMessageLoading",
  GET_MENTOR_MENTEE_MESSAGES_HISTORY: "getMentorMenteeMessagesHistory",
  GET_MENTOR_MENTEE_MESSAGES_HISTORY_LOADING: "getMentorMenteeMessagesHistoryLoading",
  UPDATE_MENTORING_LOADING: "updateMentoring_loading",
  GET_BECOMING_MENTOR_LOADING: "getBecomingMentor_loading",
  GET_BECOMING_MENTOR: "getBecomingMentor",
  GET_REQUEST_MENTOR: "getRequestMentor",
  GET_REQUEST_MENTOR_LOADING: "getRequestMentorLoading",
  CANCEL_MY_MENTOR: "cancelMyMentor",
  CANCEL_MY_MENTOR_LOADING: "cancelMyMentorLoading",
  CANCEL_MY_MENTORING: "cancelMyMentoring",
  CANCEL_MY_MENTORING_LOADING: "cancelMyMentoringLoading",
  GET_MENTORING_BASE_INFO: "getMentoringBaseInfo",
  GET_MENTORING_BASE_INFO_LOADING: "getMentoringBaseInfoLoading",
};
