export const createCookie = (name, value, days) => {
    var expires;
    if (days) {
        if (days < 0) {
            expires = "; expires=Thu, 01 Jan 1970 00:00:01 GMT;"
        } else {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
    }
    else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
};

export const delete_cookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};