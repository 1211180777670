import axios from "axios";
import { EXTERNAL_API_URL } from "../index";

export const LOGIN_URL = "account/login";
export const REGISTER_URL = "account/register";
export const REFRESH_TOKEN_URL = "account/refresh-token";
export const LOGOUT_URL = "account/logout";
export const RECOVER_PASSWORD_URL = "User/recover-password";
export const GET_USER_PERMISSIONS = "account/get_user_permissions";
export const GET_GENERATE_CONFIRMATION_TOKEN_URL =
  "account/generate-confirmation-token";
export const POST_CONFIRM_AUTHENTICATION_TOKEN_URL = "User/confirm-phone";
export const CHANGE_PASSWORD_URL = "User/change-password";
export const RESET_PASSWORD_URL = "User/reset-password";

export const GET_USER_INFO_URL = "User/get-user-info";
export const GET_USER_DOCUMENT_URL = "User/get-user-document-by-id";

export function getPersonalInformation() {
  return axios.get(EXTERNAL_API_URL + GET_USER_INFO_URL);
}

export function getUserDocument(data) {
  return axios.get(EXTERNAL_API_URL + GET_USER_DOCUMENT_URL, {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      docId: data,
    },
    responseType: "blob",
  });
}

export function login(_username, _password) {
  return axios.post(EXTERNAL_API_URL + LOGIN_URL, {
    username: _username,
    password: _password,
  });
}

export function register(user) {
  return axios.post(EXTERNAL_API_URL + REGISTER_URL, user);
}

export function logout(username) {
  return axios.post(EXTERNAL_API_URL + LOGOUT_URL, {
    email: username,
  });
}

export function refresh_token(refreshToken) {
  return axios.post(EXTERNAL_API_URL + REFRESH_TOKEN_URL, {
    refreshToken: refreshToken,
  });
}

export function recover_password(phone) {
  return axios.post(EXTERNAL_API_URL + RECOVER_PASSWORD_URL, {
    username: phone,
  });
}

export function generate_confirmation_token(username, isEmail) {
  return axios.get(EXTERNAL_API_URL + GET_GENERATE_CONFIRMATION_TOKEN_URL, {
    params: {
      userId: username,
      isEmailToken: isEmail,
    },
  });
}
export function confirm_authentication_token(username, _token) {
  return axios.post(EXTERNAL_API_URL + POST_CONFIRM_AUTHENTICATION_TOKEN_URL, {
    phone: username,
    token: _token,
  });
}

export function changePassword(formData) {
  return axios.post(EXTERNAL_API_URL + CHANGE_PASSWORD_URL, formData);
}

export function reset_password(username, password, token) {
  return axios.post(EXTERNAL_API_URL + RESET_PASSWORD_URL, {
    token: token,
    username: username,
    newPassword: password,
  });
}
