import { MENTORING } from "utils/actionsConsts";

const mentoringReducer = (
  state = {
    searchMentoring_loading: false,

    mentoringBaseInfo_loading: false,
    hasRequestedMentor: false,
    hasAssignedMentor: false, // hasMentor: false,
    hasRequestedBecomeMentor: false,
    isApprovedMentor: false, // isMentor: false,
    
    requestMentor_loading: false,
    requestedMentorData: null,
    becomeMentor_loading: false,
    becomingMentorData: [],
    searchMentorship_loading: false,
    cancelMyMentor_loading: false,
    cancelMyMentoring_loading: false,

    mentoring_details_loading: false,

    sendNewMessage_loading: false,
    mentorMenteeMessagesHistory: [],
    mentorMenteeMessagesHistory_loading: false,

    total_records: 0,
    total_pages: 0,
    refresh_results: false,

  },
  action
) => {
  switch (action.type) {
    case MENTORING.GET_MY_MENTORS:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case MENTORING.GET_MY_MENTORS_LOADING:
      return { ...state, searchMentoring_loading: action.payload };
    case MENTORING.GET_MENTORING_BASE_INFO:
      return {
        ...state,
        hasRequestedMentor: action.payload.hasRequestedMentor,
        hasAssignedMentor: action.payload.hasAssignedMentor,
        hasRequestedBecomeMentor: action.payload.hasRequestedBecomeMentor,
        isApprovedMentor: action.payload.isApprovedMentor,
      };
    case MENTORING.GET_MENTORING_BASE_INFO_LOADING:
      return { ...state, mentoringBaseInfo_loading: action.payload };
    case MENTORING.GET_MY_MENTEES:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case MENTORING.GET_MY_MENTEES_LOADING:
      return { ...state, searchMentoring_loading: action.payload };
    case MENTORING.BECOME_MENTOR:
      return { ...state, result: action.payload };
    case MENTORING.BECOME_MENTOR_LOADING:
      return { ...state, becomeMentor_loading: action.payload };
    case MENTORING.REQUEST_MENTOR:
      return { ...state, result: action.payload };
    case MENTORING.REQUEST_MENTOR_LOADING:
      return { ...state, requestMentor_loading: action.payload };
    case MENTORING.GET_MENTORING_DETAILS:
      return { ...state, mentoringDetailsData: action.payload };
    case MENTORING.CLEAR_DETAIL:
      return { ...state, mentoringDetailsData: {} };
    case MENTORING.GET_MENTORING_DETAILS_LOADING:
      return { ...state, mentoring_details_loading: action.payload };
    case MENTORING.GET_MENTOR_MENTEE_MESSAGES_HISTORY:
      return { ...state, mentorMenteeMessagesHistory: action.payload };
    case MENTORING.GET_MENTOR_MENTEE_MESSAGES_HISTORY_LOADING:
      return { ...state, mentorMenteeMessagesHistory_loading: action.payload };
    case MENTORING.SEND_NEW_MESSAGE_LOADING:
      return { ...state, sendNewMessage_loading: action.payload };
    case MENTORING.REFRESH_RESULTS:
      return { ...state, refresh_results: action.payload };
    case MENTORING.GET_BECOMING_MENTOR:
      return { ...state, becomingMentorData: action.payload };
    case MENTORING.GET_BECOMING_MENTOR_LOADING:
      return { ...state, searchMentorship_loading: action.payload };
    case MENTORING.GET_REQUEST_MENTOR:
      return { ...state, requestedMentorData: action.payload };
    case MENTORING.GET_REQUEST_MENTOR_LOADING:
      return { ...state, searchMentorship_loading: action.payload };
    case MENTORING.CANCEL_MY_MENTOR:
      return { ...state, result: action.payload };
    case MENTORING.CANCEL_MY_MENTOR_LOADING:
      return { ...state, cancelMyMentor_loading: action.payload };
    case MENTORING.CANCEL_MY_MENTORING:
      return { ...state, result: action.payload };
    case MENTORING.CANCEL_MY_MENTORING_LOADING:
      return { ...state, cancelMyMentoring_loading: action.payload };

    default:
      return state;
  }
};

export default mentoringReducer;
