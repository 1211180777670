import { APPLICATIONS_ACTIONS } from "utils/actionsConsts";

const applicationsReducer = (
  state = {
    applicationSucceeded: false,
    applicationDetailsGeneralData_loading: false,
    subprogramsComplementaryData: [],
    subprogramsInfo: {},
  },
  action
) => {
  switch (action.type) {
    case APPLICATIONS_ACTIONS.CREATE:
      return {
        ...state,
        applicationSucceeded: action.payload,
        subprogramsInfo: {},
        subprogramsComplementaryData: [],
      };
    case APPLICATIONS_ACTIONS.RESET_FORM:
      return {
        ...state,
        subprogramsInfo: {},
        subprogramsComplementaryData: [],
      };

    case APPLICATIONS_ACTIONS.GET_APPLICATION_DETAILS_GENERAL_DATA:
      return { ...state, applicationDetailsGeneralData: action.payload };
    case APPLICATIONS_ACTIONS.GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING:
      return {
        ...state,
        applicationDetailsGeneralData_loading: action.payload,
      };
    case APPLICATIONS_ACTIONS.GET_COMPLEMENTARY_DATA:
      return {
        ...state,
        subprogramsComplementaryData:
          state.subprogramsComplementaryData === undefined
            ? [
                {
                  id: action.payload.subprogramId,
                  complementaryData: action.payload.complementaryData,
                },
              ]
            : state.subprogramsComplementaryData.some(
                (subprogram) => subprogram.id === action.payload.subprogramId
              )
            ? state.subprogramsComplementaryData.map((p) =>
                p.id === action.payload.subprogramId
                  ? {
                      ...p,
                      complementaryData: action.payload.complementaryData,
                    }
                  : p
              )
            : state.subprogramsComplementaryData.push({
                id: action.payload.subprogramId,
                complementaryData: action.payload.complementaryData,
              }) > 0
            ? state.subprogramsComplementaryData
            : state.subprogramsComplementaryData,
      };
    case APPLICATIONS_ACTIONS.GET_SUBPROGRAM_INFORMATION:
      return {
        ...state,
        subprogramsInfo: {
          id: action.payload.subprogramId,
          info: action.payload.info,
        },
      };
    case APPLICATIONS_ACTIONS.GET_APPLICATIONS_IN_PROGRESS:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case APPLICATIONS_ACTIONS.GET_SUBMIT_LOADING:
      return { ...state, applicationGET_SUBMIT_LOADING: action.payload };
    case APPLICATIONS_ACTIONS.GET_USER_APPLICATIONS_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default applicationsReducer;