import { INFORMATION_DISSEMINATION } from "utils/actionsConsts";

const informationDisseminationReducer = (
  state = {
    searchInformation_loading: false,
    get_information_details_loading: false,
    refresh_results: false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION_LOADING:
      return { ...state, searchInformation_loading: action.payload };
    case INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS:
      return { ...state, informationDetailsData: action.payload };
    case INFORMATION_DISSEMINATION.CLEAR_DETAIL:
      return { ...state, informationDetailsData: {} };
    case INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING:
      return { ...state, get_information_details_loading: action.payload };
    default:
      return state;
  }
};

export default informationDisseminationReducer;
