import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import componentStylesRedirectPageLayout from "assets/theme/layouts/redirectPageLayout";
import { INEFOP_FO_URL } from "index";
import { labels } from "resources/resources";

const useStylesRedirectPageLayout = makeStyles(componentStylesRedirectPageLayout);

const Search = (props) => {
  const classes = useStylesRedirectPageLayout();
  const intl = useIntl();

  const [countDown, setCountDown] = useState(10);

  useEffect(() => {
    if (countDown <= 0) {
      window.location.replace(INEFOP_FO_URL);
    } else {
      const interval = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countDown]);

  return (
    <div className={classes.container}>
      <img alt={"Logotipo PAPE"} src={require("assets/img/brand/PAPE_azul.png").default} className={classes.logo} />
      <div>{intl.formatMessage(labels.Label_Redirect_To_INEDOP_Description_1)}</div>
      <div>
        {intl.formatMessage(labels.Label_Redirect_To_INEDOP_Description_2)} {countDown}
      </div>
      <div className={classes.description}>{intl.formatMessage(labels.Label_Redirect_To_INEDOP_Description_3)}</div>
    </div>
  );

  // const classes = useStyles();
  // const location = useLocation();

  // React.useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  // }, [location]);

  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.collapse) {
  //       return getRoutes(prop.views);
  //     }
  //     if (prop.layout === "/search") {
  //       return (
  //         <Route
  //           path={prop.layout + prop.path}
  //           component={prop.component}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };

  // if (props.authState.loggedIn === undefined || !props.authState.loggedIn) {
  //   return <Redirect to="/auth/home" />;
  // } else {
  //   return (
  //     <>
  //       <Box>
  //         <Box position="relative" flex="1" className={classes.mainContent}>
  //           {location.pathname === "/admin/alternative-dashboard" ? (
  //             <AdminNavbarAlternative/>
  //           ) : (
  //             <AdminNavbar/>
  //           )}
  //           <GradientHeader />
  //           <Switch>
  //             {getRoutes(routes)}
  //             <Redirect from="*" to="/admin/home" />
  //           </Switch>
  //         </Box>

  //         <AdminFooter />
  //       </Box>
  //     </>
  //   );
  // }
};

// export default Auth;
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, {})(Search);
