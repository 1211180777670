import { generate_confirmation_token, confirm_authentication_token } from "crud/auth.crud";
import { CONFIRMATION_ACTIONS } from "utils/const";
import { DIALOG } from "utils/actionsConsts";
// import { recoverPasswordAction } from "./recoverPasswordAction";
import logoutAction from "./logoutAction";
import { recover_password } from "crud/auth.crud";

export const showAuthenticationModalAction = (toShow, username, action) => async (dispatch) => {
  if (!toShow)
    dispatch({
      type: "showConfirmationModal",
      payload: { toShow: false },
    });
  else {
    switch (action) {
      case CONFIRMATION_ACTIONS.CONFIRMATION:
        generate_confirmation_token(username, false)
          .then(() => {
            dispatch({
              type: "showConfirmationModal",
              payload: {
                toShow: true,
                actionType: action,
                mobilePhone: username,
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: DIALOG.SHOW,
              payload: {
                showGenericDialog: true,
                genericDialogData: {
                  type: DIALOG.ONE_BUTTON_TYPE,
                  isSuccess: false,
                  message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
                },
              },
            });
            console.log("error", error);
          });
        break;
      case CONFIRMATION_ACTIONS.RECOVER_PASSWORD:
        recover_password(username)
          .then(() => {
            dispatch({
              type: "showConfirmationModal",
              payload: {
                toShow: true,
                actionType: action,
                mobilePhone: username,
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: DIALOG.SHOW,
              payload: {
                showGenericDialog: true,
                genericDialogData: {
                  type: DIALOG.ONE_BUTTON_TYPE,
                  isSuccess: false,
                  message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
                },
              },
            });
            console.log("error", error);
          });
        break;
      default:
        dispatch({
          type: "showConfirmationModal",
          payload: {
            toShow: true,
            actionType: action,
            mobilePhone: username,
          },
        });
        break;
    }
  }
};
export const confirmAuthenticationAction = (username, token, action) => async (dispatch) => {
  switch (action) {
    case CONFIRMATION_ACTIONS.RECOVER_PASSWORD:
      dispatch(showNewPasswordModalAction(true, username, token));
      break;
    case CONFIRMATION_ACTIONS.CONFIRMATION:
      confirm_authentication_token(username, token)
        .then((res) => {
          dispatch({
            type: DIALOG.SHOW,
            payload: {
              showGenericDialog: true,
              genericDialogData: {
                type: DIALOG.ONE_BUTTON_TYPE,
                isSuccess: res.data,
                message: res.data ? "Conta confirmada com sucesso." : "Código de confirmação incorreto. Tente novamente",
                okAction: () => { dispatch(logoutAction(username)) },
                onCloseAction: () => { dispatch(logoutAction(username)) },
              },
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: DIALOG.SHOW,
            payload: {
              showGenericDialog: true,
              genericDialogData: {
                type: DIALOG.ONE_BUTTON_TYPE,
                isSuccess: false,
                message: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
                okAction: () => { dispatch(logoutAction(username)) },
                onCloseAction: () => { dispatch(logoutAction(username)) },
              },
            },
          });
          ;
          console.log("error", error);
        });
      break;
    default:
      return;
  }
};

export const showNewPasswordModalAction = (toShow, username, token) => async (dispatch) => {
  dispatch({
    type: "showNewPasswordModal",
    payload: {
      toShow: toShow,
      mobilePhone: username,
      resetToken: token,
    },
  });
};
