export const DATASET = {
  CONDITIONS: "Conditions",
  USER_APPLICATIONS_AWAITING_TRAINING: "UserApplicationsAwaitingTraining",

  APPLICATION_REJECT_MOTIVES: "ApplicationsRejectMotives",
  BENEFITS: "Benefits",
  CANCELING_MOTIVES: "CancelingMotives",
  CLASS: "Class",
  DEGREE: "Degree",
  HISTORY_ENTRY_TYPES: "HistoryEntryTypes",
  LOAN_REJECT_MOTIVES: "LoansRejectMotives",
  MUNICIPALITIES: "Municipalities",
  NATIONALITIES: "Nationalities",
  APPLICATION_NOTIFICATION_MOTIVES: "NotificationMotives",
  APPLICATION_NOTIFICATION_RECIPIENT_TYPES: "NotificationRecipientTypes",
  APPLICATION_NOTIFICATION_SUBJECTS: "NotificationSubjects",
  ORIGIN: "Origin",
  PROFESSIONAL_CATEGORY: "ProfessionalCategory",
  PROFESSIONAL_EXPIRIENCE: "ProfessionalExperience",
  LOSS_BREAK_MOTIVES: "ProfessionalKitBreakLossMotives",
  PROVINCES: "Provinces",
  APPLICATION_SCHEDULING_MOTIVES: "SchedulingMotives",
  SPECIAL_NEEDS: "SpecialNeeds",
  SPECIALTY: "Specialty",
  TECHNICAL_CAPABIBILITY: "TechnicalCapability",


  APPLICATION_STATES: "ApplicationStates",
  PROFESSIONAL_TRAINNING: "ProfessionalTraining",
  ENTREPRENEURSHIP_TRAINNING: "EntrepreneurshipTraining",
  SMALL_BUSINESS_MGMT_TRAINNING: "SmallBusinessMgmtTraining",
  BUSINESS_PURPOSE: "BusinessPurpose",
  ACADEMIC_LEVEL: "AcademicLevel",
  GENDER: "Gender",
  MARITAL_STATUS: "MaritalStatus",
  PROGRAMS: "Programs",
  SUBPROGRAM_STATES: "SubprogramStates",
  DEPARTMENTS: "Departments",
  CATEGORIES: "Categories",
  ACTIVE_CATEGORIES: "ActiveCategories",
  INACTIVE_CATEGORIES: "InactiveCategories",
  NOT_ASSIGNED_ACTIVE_CATEGORIES: "NotAssignedActiveCategories",
  WAREHOUSES: "Warehouses",
  DATASET_TYPES: "ReferenceDataSets",
  DOCUMENTS: "Documents",
  CONDITION_TYPES: "ConditionTypes",
  SUBPROGRAMS: "Subprograms",
  PROGRAM_SUBPROGRAMS: "ProgramSubprograms",
  BACKOFFICE_USERS: "getBackOfficeUsers",
  BACKOFFICE_ROLES: "getBackOfficeRoles",
  yesOrNoDropDown: [
    { code: true, label: "Sim" },
    { code: false, label: "Não" },
  ],
  SHAPE_PROGRAMS: "getShapePrograms",
  SHAPE_SUBSITES: "getShapeSubsites",
  BANK: "Bank",
  LOAN_STATES: "LoanStates",
  INFORMATION_DISSEMINATION_TYPE: "InformationDisseminationType",
  OPPORTUNITY_TYPE: "OpportunityType",
  CONTRACT_TYPE: "ContractType",
  INTERNSHIP_TYPE: "InternshipType",
  TIMETABLE_TYPE: "TimetableType",
  OPPORTUNITY_STATE: "OpportunityState",
  OPPORTUNITY_STATE_MOTIVES: "OpportunityStateMotives",
  OPPORTUNITY_CANDIDATE_REJECT_MOTIVES: "OpportunityCandidateRejectionMotives",
  OPPORTUNITY_DOCUMENT_TYPE: "OpportunityDocumentType",
  OPPORTUNITY_CANDIDATE_STATE: "OpportunityCandidateState",
  OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE: "OpportunityCandidateFollowUpState",

  FEEDBACK_REPORT_QUESTION_TYPE: "FeedbackReportQuestionType",
  
  MENTORING_AREA: "MentoringArea",
  MENTORING_MOTIVES: "MentoringMotives",

  infomrationDisseminationPublishPeriodDropDown: [
    { code: 0, label: "Últimas 24h", keyValue: 1 },
    { code: 1, label: "Últimos 7 dias", keyValue: 7 },
    { code: 2, label: "Últimos 30 dias", keyValue: 30 }, 
  ],

  USER_DOCUMENT_TYPES: "FO_UserDocumentTypes"
};

//Registration
export const REGISTRATION_CONSTS = {
  DATE_TIME_FORMAT: "DD/MM/YYYY",
  DATE_TIME_PLACEHOLDER: "DD/MM/AAAA",
  MIN_BIRTHDATE: "31/12/1899",
  MAX_LENGTH_ID_CARD: 14,
  MIN_LENGTH_PHONE_NUMBER: 100000000,
  MAX_LENGTH_PHONE_NUMBER: 999999999,
  MIN_LENGTH_PERCENTAGE: 0,
  MAX_LENGTH_PERCENTAGE: 100,
};

//User confirmation action types
export const CONFIRMATION_ACTIONS = {
  LOGIN: "1",
  RECOVER_PASSWORD: "2",
  CONFIRMATION: "3",
};
export const AUTH_COOKIE_NAME = "SIGPAPE";

export const LOGIN_PENDING = "O utilizador está pendente de aprovação";

export const LOGIN_USER_DISABLED = "O utilizador está inactivo";

export const CONFIRMATION_TOKEN_LENGTH = 6;
//Applications
export const COMPLEMENTARY_DATA_ANSWER_TYPE = {
  DATASET: 1,
  TEXT: 2,
  BOOLEAN: 3,
};

export const FORM_FIELDS_PREFIX = {
  DOCUMENT: "doc_",
  COMPLEMENTARY_DATA: "cd_",
  FOLLOWUP_FORM_DATA: "ffd_"
};

//GENERAL
export const REGEX_PATTERNS = {
  MONETARY: /^(\d+(?:[,]\d{1,2})?|)$/,
  EMAIL: /\S+@\S+\.\S+/,
  DATE:/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
};


export const MONETARY_DECIMAL_SEPARATOR = {
  WEB: ",",
  API: ".",
};
export const MONETARYUNITPLACEHOLDER = "00" + MONETARY_DECIMAL_SEPARATOR.WEB + "00";

export const UNITS = {
  MONETARY: "Kz",
  MONTHS: "Meses",
  INTEREST: "%",
};

export const PAGINATION = {
  PAGE_SIZE: 15,
  PAGE_SIZE_MODAL_RESULT_TABLE: 10,
  PAGE_SIZE_DISSEMINATION: 12,
  PAGE_SIZE_HIGHLIGHTED_INFORMATION: 6,
  PAGE_SIZE_AVAILABLE_OPPORTUNITIES: 12,
  FIRST_PAGE: 1,
};

//SHAPE
export const SHAPE_PROGRAMS_TARGET = {
  AVANCO: 1,
  CAPACITA: 2,
  PROFESSIONAL_WALLET: 3,
};

export const BENEFIT = {
  PROFESSIONAL_KIT: "ProfessionalKit",
  MICROCREDIT: "Microcredit",
  PROFESSIONAL_WALLET: "ProfessionalWallet",
  CAPACITA: "Capacita",
  AVANCO: "Avanco",
  PROFESSIONAL_INTERNSHIP: "ProfessionalInternship",
  PROFESSIONAL_KIT_MICROCREDIT: "ProfessionalKitMicrocredit",
};

export const APPLICATION_STATES_KEY = {
  SUBMETIDA: "1",
  AGUARDA_INFORMACAO: "2",
  AGUARDA_FORMACAO_EM_FALTA: "3",
  AGUARDA_ACEITACAO_FORMACAO: "4",
  AGUARDA_FORMACAO: "5",
  EM_ANALISE: "6",
  AGUARDA_VISITA: "7",
  AGUARDA_TESTE: "8",
  SUSPENSA: "9",
  AGUARDA_INFORMACAO_BANCO: "10",
  AGUARDA_AGENDAMENTO_ENTREGA_KIT: "11",
  ENTREGA_KIT_AGENDADA: "12",
  REJEITADA: "13",
  ACEITE: "14",
  CANCELADA: "15"
};

export const APPLICATION_STATES_IN_PROGRESS = [
  APPLICATION_STATES_KEY.SUBMETIDA,
  APPLICATION_STATES_KEY.AGUARDA_INFORMACAO,
  APPLICATION_STATES_KEY.AGUARDA_FORMACAO_EM_FALTA,
  APPLICATION_STATES_KEY.AGUARDA_ACEITACAO_FORMACAO,
  APPLICATION_STATES_KEY.AGUARDA_FORMACAO,
  APPLICATION_STATES_KEY.EM_ANALISE,
  APPLICATION_STATES_KEY.AGUARDA_VISITA,
  APPLICATION_STATES_KEY.AGUARDA_TESTE,
  APPLICATION_STATES_KEY.SUSPENSA,
  APPLICATION_STATES_KEY.AGUARDA_INFORMACAO_BANCO,
  APPLICATION_STATES_KEY.AGUARDA_AGENDAMENTO_ENTREGA_KIT,
  APPLICATION_STATES_KEY.ENTREGA_KIT_AGENDADA,
];

export const APPLICATION_STATES_CANCELED = [
  APPLICATION_STATES_KEY.REJEITADA,
  APPLICATION_STATES_KEY.CANCELADA
];

export const APPLICATION_STATES_APPROVED = [
  APPLICATION_STATES_KEY.ACEITE
];

export const INFORMATION_DISSEMINATION_TYPE_KEYVALUE = {
  INFORMATION: "information",
  LEGISLATION: "legislation",
  SCHEDULE: "schedule",
};

export const OPPORTUNITY_TYPE_KEY = {
  EMPREGO: "Emprego",
  ESTAGIO_PROFISSIONAL: "EstagioProfissional"
};

export const INTERNSHIP_TYPE_KEY = {
  GERAL: "Geral",
  PAPE: "PAPE",
  RETFOP: "RETFOP"
};

export const UPDATE_OPPORTUNITY_STATE_ACTION_TYPE = {
  APPROVE: 1,
  CLOSE: 2,
  REJECT: 3,
  UPDATE: 4
};

export const OPPORTUNITY_MOTIVES_TYPE_KEY = {
  CLOSE: "OpportunityCloseMotives",
  REJECT: "OpportunityRejectMotives"
};

export const OPPORTUNITY_STATE_KEY = {
  SUBMETIDA: "Submetida",
  REJEITADA: "Rejeitada",
  APROVADA: "Aprovada",
  FECHADA: "Fechada"
};

export const OPPORTUNITY_CANDIDATE_STATE_KEY = {
  EMRECRUTAMENTO: "EmRecrutamento",
  REJEITADA: "Rejeitada",
  APROVADA: "Aprovada"
};

export const OPPORTUNITY_CANDIDATE_FOLLOWUP_STATE_KEY = {
  PORINICIAR: "PorIniciar",
  ADECORRER: "ADecorrer",
  TERMINADO: "Terminado"
};

export const OPPORTUNITY_CANDIDATE_FOLLOWUP_FORMS_STATE_KEY = {
  PORSUBMETER: "AwaitingSubmission",
  SUBMETIDO: "Submitted",
  VALIDADO: "Validated",
  REJEITADO: "Rejected",
  PAGO:"Paid"
};

export const FEEDBACK_REPORT_QUESTION_TYPE_KEY = {
  TEXT: "Text",
  STAR_CLASSIFICATION: "StarClassification"
}

//Operations
export const OPERATIONS = {
  APPLICATIONS_CREATE_ITEM: 'FOCreateApplicationButton',
  APPLICATIONS_MENU: 'FOApplicationsMenu',
  APPLICATIONS_LIST_ITEM: 'FOApplicationsListItem',
  OPPORTUNITY_USER_COLECTIVE_MENU: 'FOUserColectiveOpportunityMenu',
  OPPORTUNITY_USER_SINGULAR_MENU: 'FOUserSingularOpportunityMenu',
  OPPORTUNITY_CREATE_BUTTON: 'FOUserColectiveCreateOpportunityButton',
  OPPORTUNITY_USER_SINGULAR_APPLICATIONS_LIST_ITEM: 'FOUserSingularOpportunityApplicationsListItem',
  OPPORTUNITY_USER_SINGULAR_APPLICATIONS_FOLLOWUP_LIST_ITEM: 'FOUserSingularOpportunityApplicationsFollowUpListItem',
  OPPORTUNITY_USER_SINGULAR_OPPORTUNITIES_LIST_ITEM: 'FOUserSingularOpportunitiesListItem',
  OPPORTUNITY_USER_COLECTIVE_APPLICATIONS_LIST_ITEM: 'FOUserColectiveOpportunityApplicationsListItem',
  OPPORTUNITY_USER_COLECTIVE_APPLICATIONS_FOLLOWUP_LIST_ITEM: 'FOUserColectiveOpportunityApplicationsFollowUpListItem',
  OPPORTUNITY_USER_COLECTIVE_OPPORTUNITIES_LIST_ITEM: 'FOUserColectiveOpportunitiesListItem',
  INFORMATION_DISSEMINATION_MENU: 'FOInformationDisseminationMenu',
  INFORMATION_DISSEMINATION_BOARD_ITEM: 'FOInformationDisseminationBoardItem',
  INFORMATION_DISSEMINATION_AGENDA_ITEM: 'FOInformationDisseminationAgendaItem',
  INFORMATION_DISSEMINATION_LEGISLATION_ITEM: 'FOInformationDisseminationLegislationItem',
  CONTACTS_MENU: 'FOContactMenu',
  PROFESSIONAL_TRAINING_MENU: 'FOProfessionalTrainingMenu',
  MENTORING_MENU: 'FOMentoringMenu',
  MENTORING_BECOME_MENTOR: "FOMentoringBecomeMentor",
  MENTORING_REQUEST_MENTOR: "FOMentoringRequestMentor",
};

export const SPECIAL_NEEDS_KEY = {
  NAO: "SpecialNeeds_01",
  INTELECTUAL: "SpecialNeeds_02",
  FISICA: "SpecialNeeds_03",
  SENSORIAL_VISUAL: "SpecialNeeds_04",
  SENSORIAL_AUDIBLE: "SpecialNeeds_05"
};

export const MENTORING_TYPE = {
  MENTOR: "mentor",
  MENTEE: "mentee",
  MY_MENTOR: "myMentor",
  MY_MENTEES: "myMentees"
};

export const MENTORING_MOTIVES_TYPES = {
  // BackOffice
  REJECT_MENTOR: "rejectMentor",
  CANCEL_MENTOR: "cancelMentor",
  CANCEL_MENTEE: "cancelMentee",
  DISASSOCIATE_MENTOR: "disassociateMentor",
  DISASSOCIATE_MENTEE: "disassociateMentee",
  // FrontOffice
  CANCEL_MY_MENTOR: "cancelMyMentor",
  CANCEL_MY_MENTORING: "cancelMyMentoring",
}

export const USER_TYPE_KEY = {
  INDIVIDUAL: "Singular",
  COLETIVO: "Colective",
};