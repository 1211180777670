import { OPPORTUNITIES_ACTIONS } from "utils/actionsConsts";

const opportunitiesReducer = (
  state = {
    opportunityCreationSucceeded: false,
    opportunityUpdateLoading: false,
    opportunitySubmitLoading: false,
    opportunityCandidateCreationSucceeded: false,
    opportunityCandidateSubmitLoading: false,
    searchOpportunities_loading: false,
    searchAvailableOpportunities_loading: false,
    searchRecommendedOpportunities_loading: false,
    candidateInfo_loading: false,
    opportunityUsers_loading: false,
    approveCandidate_loading: false,
    rejectCandidate_loading: false,
    possibleCandidates_loading: false,
    possibleCandidates_result: [],
    possibleCandidates_total_records: 0,
    possibleCandidates_total_pages: 0,
    addOpportunityCandidatesList_loading: false,
    getMyOpportunityApplications_loading: false,
    get_opportunity_application_details_loading: false,
    opportunityApplicationDetailsData: undefined,
    getUserColectiveOpportunityApplications_loading:false,
    isOpportunityApplication: false,
    getMyOpportunityApplicationsFollowUp_loading: false,
    getOpportunityApplicationsFollowUp_loading: false,
    update_opportunity_followup_period_loading: false,
    getFeedbackReportDetails_loading: false,
    updateFeedbackReport_loading: false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case OPPORTUNITIES_ACTIONS.CREATE:
      return {
        ...state,
        opportunityCreationSucceeded: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.GET_SUBMIT_LOADING:
      return { ...state, opportunitySubmitLoading: action.payload };
    case OPPORTUNITIES_ACTIONS.CREATE_OPPORTUNITY_CANDIDATES:
      return {
        ...state,
        opportunityCandidateCreationSucceeded: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.GET_SUBMIT_OPPORTUNITY_CANDIDATE_LOADING:
      return { ...state, opportunityCandidateSubmitLoading: action.payload };
    case OPPORTUNITIES_ACTIONS.UPDATE:
      return {
        ...state,
        opportunityUpdateSucceeded: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.GET_UPDATE_LOADING:
      return { ...state, opportunityUpdateLoading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_AVAILABLE_OPPORTUNITIES_ACTIONS:
      return {
        ...state,
        available_result: action.payload,
        available_total_records: action.payload.total_records,
        available_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_RECOMMENDED_OPPORTUNITIES_ACTIONS:
      return {
        ...state,
        recommended_result: action.payload,
      };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING:
      return { ...state, searchOpportunities_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED__AVAILABLE_OPPORTUNITIES_ACTIONS_LOADING:
      return { ...state, searchAvailableOpportunities_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_RECOMMENDED_OPPORTUNITIES_ACTIONS_LOADING:
      return { ...state, searchRecommendedOpportunities_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING:
      return { ...state, update_opportunity_state_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING:
      return { ...state, get_opportunity_details_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_DETAILS_ACTIONS:
      return { ...state, opportunityDetailsData: action.payload };
    case OPPORTUNITIES_ACTIONS.CLEAR_OPPORTUNITY_DETAILS_ACTIONS:
      return { ...state, opportunityDetailsData: undefined };
    case OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION:
      return { ...state, candidateInfo: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_CANDIDATE_INFORMATION_LOADING:
      return { ...state, candidateInfo_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_USERS_ACTIONS_LOADING:
      return { ...state, opportunityUsers_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.APPROVE_CANDIDATE_LOADING:
      return { ...state, approveCandidate_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.REJECT_CANDIDATE_LOADING:
      return { ...state, rejectCandidate_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING:
      return { ...state, possibleCandidates_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES:
      return {
        ...state,
        possibleCandidates_result: action.payload,
        possibleCandidates_total_records: action.payload.total_records,
        possibleCandidates_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING:
      return { ...state, addOpportunityCandidatesList_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS:
      return {
        ...state,
        myOpportunityApplications_result: action.payload,
        myOpportunityApplications_total_records: action.payload.total_records,
        myOpportunityApplications_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_LOADING:
      return { ...state, getMyOpportunityApplications_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS_LOADING:
      return { ...state, get_opportunity_application_details_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS:
      return { ...state, opportunityApplicationDetailsData: action.payload };
    case OPPORTUNITIES_ACTIONS.CLEAR_OPPORTUNITY_APPLICATION_DETAILS_ACTIONS:
      return { ...state, opportunityApplicationDetailsData: undefined };
    case OPPORTUNITIES_ACTIONS.GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS:
      return {
        ...state,
        userColectiveOpportunityApplications_result: action.payload,
        userColectiveOpportunityApplications_total_records: action.payload.total_records,
        userColectiveOpportunityApplications_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_LOADING:
      return { ...state, getUserColectiveOpportunityApplications_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_IS_OPPORTUNITY_APPLICATION:
      return { ...state, isOpportunityApplication: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP:
      return {
        ...state,
        myOpportunityApplicationsFollowUp_result: action.payload,
        myOpportunityApplicationsFollowUp_total_records: action.payload.total_records,
        myOpportunityApplicationsFollowUp_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_MY_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING:
      return { ...state, getMyOpportunityApplicationsFollowUp_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_FOLLOWUP:
      return {
        ...state,
        userColectiveOpportunityApplicationsFollowUp_result: action.payload,
        userColectiveOpportunityApplicationsFollowUp_total_records: action.payload.total_records,
        userColectiveOpportunityApplicationsFollowUp_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_USER_COLECTIVE_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING:
      return { ...state, getUserColectiveOpportunityApplicationsFollowUp_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS:
      return {
        ...state,
        opportunityApplicationsFollowUpDetails_result: action.payload,
        opportunityApplicationsFollowUpDetails_total_records: action.payload.total_records,
        opportunityApplicationsFollowUpDetails_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING:
      return { ...state, getOpportunityApplicationsFollowUpDetails_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING:
      return { ...state, update_opportunity_followup_period_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS:
      return {
        ...state,
        feedbackReportDetails_result: action.payload,
        feedbackReportDetails_total_records: action.payload.total_records,
        feedbackReportDetails_total_pages: action.payload.total_pages,
      };
    case OPPORTUNITIES_ACTIONS.GET_FEEDBACK_REPORT_DETAILS_LOADING:
      return { ...state, getFeedbackReportDetails_loading: action.payload };
    case OPPORTUNITIES_ACTIONS.UPDATE_FEEDBACK_REPORT_LOADING:
      return { ...state, updateFeedbackReport_loading: action.payload };
    default:
      return state;
  }
};

export default opportunitiesReducer;
