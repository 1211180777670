import { defineMessages } from "react-intl";

export const errorMessages = defineMessages({
  ErrorMessage_Birthdate_MaxDate: {
    id: "ErrorMessage_Birthdate",
    defaultMessage: "A data deve ser inferior à data atual",
    description: "Mensagem de erro quando um campo de data de nascimento tem uma data superior à atual",
  },
  ErrorMessage_Birthdate_MinDate: {
    id: "ErrorMessage_Birthdate_MinDate",
    defaultMessage: "A data deve ser superior a {minDate}",
    description: "Mensagem de erro quando um campo de data de nascimento tem uma data inferior à data mínima",
  },
  ErrorMessage_FieldRequired: {
    id: "ErrorMessage_FieldRequired",
    defaultMessage: "O campo é obrigatório",
    description: "Mensagem de erro quando um campo obrigatório do formulário não é preenchido",
  },
  ErrorMessage_FieldPercentage: {
    id: "ErrorMessage_FieldPercentage",
    defaultMessage: "O valor mínimo da % deve ser maior que 0 e menor que 100.",
    description: "Mensagem de erro quando ocorre um erro a % é incorrecta",
  },
  ErrorMessage_FieldValidation: {
    id: "ErrorMessage_FieldValidation",
    defaultMessage: "Confirme o valor do campo",
    description:
      "Mensagem de erro quando um campo obrigatório do formulário é pré-preenchido mas precisa de confirmação",
  },
  ErrorMessage_GenericError: {
    id: "ErrorMessage_GenericError",
    defaultMessage: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
    description: "Mensagem de erro quando ocorre um erro genérico na API",
  },
  ErrorMessage_InvalidFormat: {
    id: "ErrorMessage_InvalidFormat",
    defaultMessage: "Formato inválido",
    description: "Mensagem de erro quando um campo do formulário tem um formato inválido",
  },
  ErrorMessage_MinRegisterDate: {
    id: "ErrorMessage_MinRegisterDate",
    defaultMessage: "A idade mínima para registo é de 17 anos.",
    description: "Mensagem de erro quando a data de nascimento para registo não perfaz 17 anos.",
  },
  ErrorMessage_ID_Card_InvalidFormat: {
    id: "ErrorMessage_ID_Card_InvalidFormat",
    defaultMessage: "O número de BI tem tamanho máximo de 14 caracteres",
    description: "Mensagem de erro quando um campo do formulário tem um formato inválido",
  },
  ErrorMessage_MobilePhone_ConfirmationMobilePhone_Not_Match: {
    id: "ErrorMessage_MobilePhone_ConfirmationMobilePhone_Not_Match",
    defaultMessage: "O número inserido deve ser igual ao campo {phoneLabel}",
    description: "Mensagem de erro quando o valor do campo de confirmação de telemóvel não é igual ao telemóvel",
  },
  ErrorMessage_Invalid_Value: {
    id: "ErrorMessage_Invalid_Value",
    defaultMessage: "Valor inválido.",
    description: "Mensagem de erro quando o valor inserido é inválido.",
  },
  ErrorMessage_Email_ConfirmationEmail_Not_Match: {
    id: "ErrorMessage_Email_ConfirmationEmail_Not_Match",
    defaultMessage: "O e-mail inserido deve ser igual ao campo {emailLabel}",
    description: "Mensagem de erro quando o valor do campo de confirmação do e-mail não é igual ao e-mail",
  },
  ErrorMessage_Password_Not_Valid: {
    id: "ErrorMessage_Password_Not_Valid",
    defaultMessage: "Palavra-passe inválida",
    description: "Mensagem de erro quando a palavra-passe inserida no registo não cumpre os requisitos",
  },
  ErrorMessage_Invalid_ProfessionalKit_Value: {
    id: "ErrorMessage_Invalid_ProfessionalKit_Value",
    defaultMessage: "O número de kits não pode ser superior a {numMaxKits}.",
    description: "Mensagem de erro quando o valor inserido é inválido.",
  },
  ErrorMessage_Generic_InvalidField: {
    id: "ErrorMessage_Generic_InvalidField",
    defaultMessage: "Formato incorrecto",
    description: "Mensagem genérica para qualquer campo que não cumpra o formato indicado",
  },
  ErrorMessage_Generic_ValueGreaterThanZeroField: {
    id: "ErrorMessage_Generic_ValueGreaterThanZeroField",
    defaultMessage: "O valor tem de ser superior a zero",
    description: "Mensagem genérica para qualquer campo que o valor tenha de ser superior a zero",
  },
  ErrorMessage_Generic_DateToGreaterThanDateFromField: {
    id: "ErrorMessage_Generic_DateToGreaterThanDateFromField",
    defaultMessage: "A data deve ser superior à data de inicio",
    description: "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser superior à data de fim",
  },
  ErrorMessage_Generic_DateFromLessThanDateToField: {
    id: "ErrorMessage_Generic_DateFromLessThanDateToField",
    defaultMessage: "A data deve ser inferior à data de fim",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser superior à data de inicio",
  },
  ErrorMessage_Generic_DateLessThanTodayField: {
    id: "ErrorMessage_Generic_DateLessThanTodayField",
    defaultMessage: "A data deve ser inferior à data atual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser inferior à data actual",
  },
  ErrorMessage_Generic_DateGreaterThanTodayField: {
    id: "ErrorMessage_Generic_DateGreaterThanTodayField",
    defaultMessage: "A data deve ser igual ou superior à data actual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser igual ou superior à data actual",
  },
});
export const labels = defineMessages({
  Label_Button_Cancel: {
    id: "Label_Button_Cancel",
    defaultMessage: "Cancelar",
    description: "Label de botºoes cancelar",
  },
  Label_Button_Submit: {
    id: "Label_Button_Submit",
    defaultMessage: "Submeter",
    description: "Label de botões submeter",
  },
  Label_Reject_Submit: {
    id: "Label_Reject_Submit",
    defaultMessage: "Rejeitar",
    description: "Label de botões Rejeitar",
  },
  Label_Recruit_Submit: {
    id: "Label_Recruit_Submit",
    defaultMessage: "Recrutar",
    description: "Label de botões Recrutar",
  },
  Label_Admin_Applications_Programs_Field_ParentApplication: {
    id: "Label_Admin_Applications_Programs_Field_ParentApplication",
    defaultMessage: "Candidatura relacionada (opcional)",
    description: "Label do campo da candidatura relacionada",
  },
  Label_Admin_Applications_Programs_Field_GeneratedWorkPosts: {
    id: "Label_Admin_Applications_Programs_Field_GeneratedWorkPosts",
    defaultMessage: "Postos de trabalho a gerar",
    description: "Label do campo que irá conter os postos de trabalho a gerar",
  },
  Label_Admin_Applications_Programs_Field_Province: {
    id: "Label_Admin_Applications_Programs_Field_Province",
    defaultMessage: "Onde pretende candidatar-se?",
    description: "Label do campo de provincia da candidatura",
  },
  Label_Admin_Applications_Programs_Field_Province_Placeholder: {
    id: "Label_Admin_Applications_Programs_Field_Province_Placeholder",
    defaultMessage: "Seleccione a província em que se pretende candidatar",
    description: "Label do campo de provincia da candidatura",
  },
  Label_Admin_Applications_Programs_Field_ProgramSubprogram: {
    id: "Label_Admin_Applications_Programs_Field_ProgramSubprogram",
    defaultMessage: "Qual o programa a que pretende candidatar-se?",
    description: "Label da tabela de seleção de programa e subprograma da candidatura",
  },
  Label_Admin_Applications_Programs_Field_ProgramSubprogram_Placeholder: {
    id: "Label_Admin_Applications_Programs_Field_ProgramSubprogram_Placeholder",
    defaultMessage: "Seleccione um programa",
    description: "Placeholder dos campos da tabela de seleção de programa e subprograma da candidatura",
  },
  Label_Admin_Applications_Programs_Field_Subprogram_Placeholder: {
    id: "Label_Admin_Applications_Programs_Field_Subprogram_Placeholder",
    defaultMessage: "Seleccione um subprograma",
    description: "Placeholder dos campos da tabela de seleção de programa e subprograma da candidatura",
  },
  Label_Admin_Applications_Programs_Field_BankPreference: {
    id: "Label_Admin_Applications_Programs_Field_BankPreference",
    defaultMessage: "Qual o banco que tem preferência?",
    description: "Label do campo de seleção de banco na criação de candidatura a um subprograma de microcrédito",
  },
  Label_Admin_Applications_Programs_Field_BankPreference_Placeholder: {
    id: "Label_Admin_Applications_Programs_Field_BankPreference_Placeholder",
    defaultMessage: "Seleccione o banco",
    description: "Label do campo de seleção de banco na criação de candidatura a um subprograma de microcrédito",
  },
  Label_Auth_ColectiveRegistrationSteps_UserData_Field_MobilePhone: {
    id: "Label_Auth_ColectiveRegistrationSteps_UserData_Field_MobilePhone",
    defaultMessage: "Telemóvel",
    description: "Label do campo 'Telemóvel' do registo de entidades coletivas",
  },
  Label_Auth_ColectiveRegistrationSteps_UserData_Field_Email: {
    id: "Label_Auth_ColectiveRegistrationSteps_UserData_Field_Email",
    defaultMessage: "E-mail (opcional)",
    description: "Label do campo 'Email' do registo de entidades coletivas",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm_Button_Next: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm_Button_Next",
    defaultMessage: "SEGUINTE",
    description: "Label do botão de navegação do formulário que permite navegar para a página seguinte",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm_Button_Previous: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm_Button_Previous",
    defaultMessage: "ANTERIOR",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm_Button_Register: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm_Button_Register",
    defaultMessage: "REGISTAR",
    description: "Label do botão de registo da entidade",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm_PersonalDataStep: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm_PersonalDataStep",
    defaultMessage: "Dados pessoais",
    description: "Label do step de dados pessoais no registo de entidades individuais",
  },
  Label_Auth_RegistrationSteps_MainForm_InformationDataStep: {
    id: "Label_Auth_RegistrationSteps_MainForm_InformationDataStep",
    defaultMessage: "Informações",
    description: "Label do step de dados informações no registo de entidades",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm_ProfessionalDataStep: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm_ProfessionalDataStep",
    defaultMessage: "Dados profissionais",
    description: "Label do step de dados profissionais no registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm__PasswordStep: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm__PasswordStep",
    defaultMessage: "Palavra-Passe",
    description: "Label do step de palavra-passe no registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_MainForm__DocumentsStep: {
    id: "Label_Auth_IndividualRegistrationSteps_MainForm__DocumentsStep",
    defaultMessage: "Documentos",
    description: "Label do step de documentos no registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Name: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Name",
    defaultMessage: "Nome",
    description: "Label campo 'Nome' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Surname: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Surname",
    defaultMessage: "Apelido",
    description: "Label campo 'Apelido' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_IdentityCard: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_IdentityCard",
    defaultMessage: "Nº do BI",
    description: "Label campo 'Nº do BI' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_MobilePhone: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_MobilePhone",
    defaultMessage: "Telemóvel",
    description: "Label do campo 'Telemóvel' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForInternshipOpportunities: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForInternshipOpportunities",
    defaultMessage: "Quero aparecer nas listas de candidatos a estágios",
    description: "Label do campo 'Disponível para Oportunidades de Estágio' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForEmploymentOpportunities: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_isAvailableForEmploymentOpportunities",
    defaultMessage: "Quero aparecer nas listas de candidatos a emprego",
    description: "Label do campo 'Disponível para Oportunidades de Emprego' do registo de entidades individuais",
  },
  Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Email: {
    id: "Label_Auth_IndividualRegistrationSteps_PersonalData_Field_Email",
    defaultMessage: "E-mail (opcional)",
    description: "Label do campo 'Email' do registo de entidades individuais",
  },
  Label_Auth_Recover_Password_MobilePhone: {
    id: "Label_Auth_Recover_Password_MobilePhone",
    defaultMessage: "Telemóvel",
    description: "Label do campo 'Telemóvel' da modal de recuperação de password",
  },
  Label_Auth_Register_EntityTypeCompany: {
    id: "Label_Auth_Register_EntityTypeCompany",
    defaultMessage: "Empresa",
    description: "Label do botão que seleciona a área de registo de entidades colectivas",
  },
  Label_Auth_Register_EntityTypeIndividual: {
    id: "Label_Auth_Register_EntityTypeIndividual",
    defaultMessage: "Individual",
    description: "Label do botão que seleciona a área de registo de entidades individuais",
  },
  Label_General_OK_Button: {
    id: "Label_General_OK_Button",
    defaultMessage: "OK",
    description: "Label de botões OK",
  },
  Label_General_Enter_Button: {
    id: "Label_General_Enter_Button",
    defaultMessage: "ENTRAR",
    description: "Label de botões ENTRAR",
  },
  Label_Generic_Upload_Document: {
    id: "Label_Generic_Upload_Document",
    defaultMessage: "Carregar documento",
    description: "Label do campo",
  },

  //Redirect PAPE to INEFOP
  Label_Redirect_To_INEDOP_Description_1: {
    id: "Label_Redirect_To_INEDOP_Description_1",
    defaultMessage: "Página fora de serviço.",
    description: "Label",
  },
  Label_Redirect_To_INEDOP_Description_2: {
    id: "Label_Redirect_To_INEDOP_Description_2",
    defaultMessage: "Será redirecionado para o novo portal do INEFOP em:",
    description: "Label",
  },
  Label_Redirect_To_INEDOP_Description_3: {
    id: "Label_Redirect_To_INEDOP_Description_3",
    defaultMessage:
      "No novo portal poderá entrar com as mesmas credenciais do PAPE e terá acesso às mesmas e a novas funcionalidades!",
    description: "Label",
  },
});
export const titles = defineMessages({
  Title_Auth_Register: {
    id: "Title_Auth_Register",
    defaultMessage: "Registo",
    description: "Título do ecrã de registo",
  },
  Title_Auth_Recover_Password_Modal: {
    id: "Title_Auth_Recover_Password_Modal",
    defaultMessage: "Recuperar palavra-passe",
    description: "Título da modal de recuperação de palavra-passe",
  },
  Title_Auth_Confirmation_Modal: {
    id: "Title_Auth_Confirmation_Modal",
    defaultMessage: "Autentique a sua conta",
    description: "Título da modal de confirmação do registo",
  },
  Title_Admin_Applications_MainForm: {
    id: "Title_Admin_Applications_MainForm",
    defaultMessage: "Candidatura PAPE",
    description: "Título do ecrã de candidatura",
  },
  Title_Admin_Applications_MainForm_ProgramSubprograms: {
    id: "Title_Admin_Applications_MainForm_ProgramSubprograms",
    defaultMessage: "Programas e subprogramas",
    description: "Título da área de definição do programa e subprograma da candidatura",
  },
  Title_Admin_Applications_MainForm_ComplementaryData: {
    id: "Title_Admin_Applications_MainForm_ComplementaryData",
    defaultMessage: "Dados complementares",
    description: "Título da área de definição dos dados complementares da candidatura",
  },
  Title_Admin_Applications_MainForm_Confirmation: {
    id: "Title_Admin_Applications_MainForm_Confirmation",
    defaultMessage: "Confirmação",
    description: "Título da área de confirmação da candidatura",
  },
  Title_Applications_MainPage: {
    id: "Title_Applications_MainPage",
    defaultMessage: "Candidaturas",
    description: "Título do ecrã de consulta de candidaturas",
  },
  Title_Applications_InProgress: {
    id: "Title_Applications_InProgress",
    defaultMessage: "Candidaturas em progresso",
    description: "Título do ecrã de consulta de candidaturas em progresso",
  },
  Title_Applications_All: {
    id: "Title_Applications_All",
    defaultMessage: "As minhas candidaturas",
    description: "Título do ecrã de consulta das minhas candidaturas",
  },
  Title_Opportunity_Creation: {
    id: "Title_Opportunity_Creation",
    defaultMessage: "Criar Oferta",
    description: "Título do ecrã de criação de ofertas",
  },
  Title_Opportunity_Creation_OpportunityType_Card: {
    id: "Title_Opportunity_Creation_OpportunityType_Card",
    defaultMessage: "Tipo de Oferta",
    description: "Título do cartão de definição do tipo da oferta",
  },
  Title_Opportunity_Employment_Data_Card: {
    id: "Title_Opportunity_Employment_Data_Card",
    defaultMessage: "Dados da oferta de Emprego",
    description: "Título do cartão de definição dos dados da oferta de emprego",
  },
  Title_Opportunity_Internship_Data_Card: {
    id: "Title_Opportunity_Internship_Data_Card",
    defaultMessage: "Dados da oferta de Estágio Profissional",
    description: "Título do cartão de definição dos dados da oferta de estágio",
  },
  Title_Opportunity_Employment_RejectMotive_Card: {
    id: "Title_Opportunity_Employment_RejectMotive_Card",
    defaultMessage: "Motivo de rejeição",
    description: "Título do cartão com o motivo da rejeição da oferta",
  },
  Title_Opportunity_Employment_CloseMotive_Card: {
    id: "Title_Opportunity_Employment_CloseMotive_Card",
    defaultMessage: "Motivo de fecho",
    description: "Título do cartão com o motivo do fecho da oferta",
  },
  Title_Opportunity_Application_Internship: {
    id: "Title_Opportunity_Application_Internship",
    defaultMessage: "Candidato a Estágio Profissional",
    description: "Título do ecrã de detalhe de candidato a Estágio Profissional",
  },
  Title_Opportunity_Internship_Details_Card: {
    id: "Title_Opportunity_Internship_Details_Card",
    defaultMessage: "Detalhes da oferta de Estágio Profissional",
    description: "Título do cartão de definição dos dados da oferta de estágio",
  },
  Title_Opportunity_Employment_Details_Card: {
    id: "Title_Opportunity_Employment_Details_Card",
    defaultMessage: "Detalhes da oferta de Emprego",
    description: "Título do cartão de definição dos dados da oferta de emprego",
  },
});
export const messages = defineMessages({
  Message_Generic_MandatoryField: {
    id: "Message_Generic_MandatoryField",
    defaultMessage: "O campo é obrigatório",
    description: "Mensagem genérica para qualquer campo que seja obrigatório",
  },
  Message_Generic_InvalidField: {
    id: "Message_Generic_InvalidField",
    defaultMessage: "Formato incorrecto",
    description: "Mensagem genérica para qualquer campo que não cumpra o formato indicado",
  },
  Message_Auth_Recover_Password_Modal_1: {
    id: "Message_Auth_Recover_Password_Modal_1",
    defaultMessage: "Irá ser enviado um SMS para o número indicado, para definir uma nova palavra-passe.",
    description: "1º mensagem a aparecer na modal de recuperação de password",
  },
  Message_Auth_Recover_Password_Modal_2: {
    id: "Message_Auth_Recover_Password_Modal_2",
    defaultMessage: "Após autenticação, e por motivos de segurança, deverá proceder à substituição da palavra-passe.",
    description: "2º mensagem a aparecer na modal de recuperação de password",
  },
  Message_Auth_Recover_Password_Success: {
    id: "Message_Auth_Recover_Password_Success",
    defaultMessage: "Pedido de recuperação de palavra passe-enviado.",
    description: "Mensagem a aparecer após sucesso no pedido de recuperação de password",
  },
  Message_Auth_Confirmation_Modal: {
    id: "Message_Auth_Confirmation_Modal",
    defaultMessage:
      "Proteger a sua conta é a nossa prioridade. Confirme a sua conta inserindo o código de autorização enviado para *** *** {lastDigits}",
    description: "Mensagem a aparecer na modal de confirmação do registo",
  },
  Message_Auth_ConfirmationFooter_Modal: {
    id: "Message_Auth_ConfirmationFooter_Modal",
    defaultMessage: "Não feche esta janela enquanto não receber o sms de autenticação da sua conta.",
    description: "Mensagem a aparecer na modal de confirmação do código telefone",
  },
  Message_Auth_Password_Rules_1: {
    id: "Message_Auth_Password_Rules_1",
    defaultMessage: "A palavra-passe tem de conter:",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_2: {
    id: "Message_Auth_Password_Rules_2",
    defaultMessage: "Pelo menos 10 caracteres",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_3: {
    id: "Message_Auth_Password_Rules_3",
    defaultMessage: "Pelo menos 1 letra maiúscula",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_4: {
    id: "Message_Auth_Password_Rules_4",
    defaultMessage: "Pelo menos 1 letra minúscula",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_5: {
    id: "Message_Auth_Password_Rules_5",
    defaultMessage: "Pelo menos 1 número",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_6: {
    id: "Message_Auth_Password_Rules_6",
    defaultMessage: "Pelo menos 1 caracter especial",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Admin_Opportunity_Details_Missing_DocumentsMessage: {
    id: "Message_Admin_Opportunity_Details_Missing_DocumentsMessage",
    defaultMessage:
      "Para efectuar a candidatura tem de concluir o preenchimento do seu perfil e carregar os seguintes documentos: ",
    description:
      "Aviso que aparece quando o utilizador não tem os documentos necessários para se candidatar a uma oferta.",
  },
  Message_Admin_Applications_Create_Must_Complete_ProfileMessage: {
    id: "Message_Admin_Applications_Create_Must_Complete_ProfileMessage",
    defaultMessage: "Para efectuar a candidatura tem de concluir o preenchimento do seu perfil",
    description:
      "Aviso que aparece quando o utilizador não tem os dados do perfil necessários para efetuar candidatura PAPE.",
  },
  Message_Admin_User_Profile_User_Documents_Message: {
    id: "Message_Admin_User_Profile_User_Documents_Message",
    defaultMessage: "Para se candidatar a Empregos e Estágios profissionais deve submeter os seguintes documentos: ",
    description: "Aviso que aparece no perfil do utilizador na área de documentos.",
  },
});
export default errorMessages;
